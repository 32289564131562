<template>
  <div>
    <b-card
      v-if="currentData.length > 0"
      no-body
    >
      <b-table
        :items="currentData"
        responsive
        :fields="$i18n.locale === locale ? currentPerformanceTableColumnsSwedish : currentPerformanceTableColumnsEnglish"
        class="mb-0"
      >
        <!-- Place -->
        <template #cell(place)="data">
          <div class="flex-column">
            <span class="font-weight-bolder mr-1">{{ data.item.place }}</span>
            <b-img
              v-if="data.item.trending"
              fluid
              :src="data.item.loss ? require('@/assets/images/pages/down.png') : require('@/assets/images/pages/up.png')"
              alt="logo img"
              width="15px"
              height="8px"
            />
          </div>
        </template>
        <!-- Name -->
        <template #cell(name)="data">
          <div class="d-flex align-items-left">
            <b-avatar
              :style="backgroundColor(data.item.color)"
              class="mr-1"
            />
            <div>
              <div class="font-weight-bolder">
                <span>{{ data.item.groupname }}</span>
              </div>
            </div>
          </div>
        </template>
        <!-- Points -->
        <template #cell(points)="data">
          <div class="d-flex align-items-right">
            <span>{{ data.item.points }}</span>
          </div>
        </template>
        <!-- Goals -->
        <template #cell(goals)="data">
          <div class="d-flex align-items-center">
            <b-avatar
              v-for="goal in data.item.goals"
              :key="goal.goalID"
              size="2.5rem"
              class="mr-1"
              :style="{ backgroundColor: goal.backgroundColor }"
            ><p
              class="mt-1"
              style="font-size: 15px"
            >
              {{ goal.count }}
            </p></b-avatar>
          </div>
        </template>
      </b-table>
    </b-card>
    <b-card
      v-if="overallData.length > 0"
      no-body
    >
      <b-table
        :items="overallData"
        responsive
        :fields="$i18n.locale === locale ? overallPerformanceTableColumnsSwedish : overallPerformanceTableColumnsEnglish"
        :empty-text="$t('NO_MATCHING_RECORDS_FOUND')"
        primary-key="id"
      >
        <!-- Place -->
        <template #cell(place)="data">
          <div>
            <span class="font-weight-bolder">{{ data.item.place }}</span>
          </div>
        </template>

        <!-- Group name -->
        <template #cell(name)="data">
          <div class="d-flex align-items-center">
            <b-avatar
              :style="backgroundColor(data.item.color)"
              class="mr-1"
            />
            <div>
              <div class="font-weight-bolder">
                <span>{{ data.item.groupname }}</span>
              </div>
            </div>
          </div>
        </template>

        <!-- Victorypoints -->
        <template
          #cell(points)="data"
        >
          <div class="d-flex align-items-right">
            <span>{{ data.item.points }}</span>
          </div>
        </template>
        <!-- Batch -->
        <template #cell(badge)="data">
          <div class="d-flex align-items-center">
            <b-avatar
              v-for="batch in data.item.batchs"
              :key="batch.batchID"
              style="background-color: transparent"
              badge-variant="success"
              class="mr-1"
              :badge="batch.count.toString()"
              :src="resolveBatchUrl(batch.batchID)"
            />
          </div>
        </template>
        <!-- Goals -->
        <template #cell(goals)="data">
          <div class="d-flex align-items-center">
            <b-avatar
              v-for="goal in data.item.goals"
              :key="goal.goalID"
              size="2.5rem"
              class="mr-1"
              :style="{ backgroundColor: goal.backgroundColor }"
            ><p
              class="mt-1"
              style="font-size: 15px"
            >
              {{ goal.count }}
            </p></b-avatar>
          </div>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BTable, BAvatar, BImg,
} from 'bootstrap-vue'
import Leaderboard from './Leaderboard'
import { resolveBatchUrl } from '@/constants/utils'
import constants from '@/constants/static.json'

export default {
  components: {
    BCard,
    BTable,
    BAvatar,
    BImg,
  },
  props: {
    currentData: {
      type: Array,
      default: () => [],
    },
    overallData: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    locale: constants.LOCALE.SWEDISH,
  }),
  setup() {
    const {
      currentPerformanceTableColumnsEnglish,
      overallPerformanceTableColumnsEnglish,
      currentPerformanceTableColumnsSwedish,
      overallPerformanceTableColumnsSwedish,
      backgroundColor,
    } = Leaderboard()

    return {
      currentPerformanceTableColumnsEnglish,
      overallPerformanceTableColumnsEnglish,
      currentPerformanceTableColumnsSwedish,
      overallPerformanceTableColumnsSwedish,
      backgroundColor,
      resolveBatchUrl,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";
</style>
