<template>
  <b-card v-if="data.length > 0">
    <b-card-body>
      <div class="podium">
        <div
          v-for="(item, index) in data.slice(0, 3)"
          :key="index"
          class="podium-step"
          :class="`place-${item.place}`"
          :style="{
            backgroundColor: item.color,
            height: calculateHeight(item.place),
          }"
        >
          <div class="step-number">
            {{ item.place }}
          </div>
          <div class="step-title">
            {{ getFirstName(item.groupname) }}
          </div>
          <div class="step-title">
            {{ getLastName(item.groupname) }}
          </div>
          <div
            v-if="item.place === 1"
            :class="data.length === 2 ? 'crownSilver' : 'crown'"
            :style="{ backgroundImage: `url(${crownImagePath})` }"
          />
        </div>
      </div>
    </b-card-body>
  </b-card>
  <div v-else>
    <leaderboard-alert :data="alert" />
  </div>
</template>

<script>
import { BCard, BCardBody } from 'bootstrap-vue'
import crownImage from '@/assets/images/pages/crown.png'
import LeaderboardAlert from './LeaderboardAlert.vue'

export default {
  name: 'Podium',
  components: {
    BCard,
    BCardBody,
    LeaderboardAlert,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      crownImagePath: crownImage,
      alert: 'The medals will be displayed here',
    }
  },
  methods: {
    getFirstName(fullName) {
      // Split the name by space and return the first part
      return fullName.split(' ')[0]
    },
    getLastName(fullName) {
      // Split the name by space and return the first part
      return fullName.split(' ')[1]
    },
    calculateHeight(place) {
      switch (place) {
        case 1:
          return '110px'
        case 2:
          return '95px'
        case 3:
          return '85px'
        default:
          return '80px' // Default case if there are more than 3 places
      }
    },
  },
}
</script>

<style scoped>
.podium {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  position: relative;
}

.podium-step {
  border-radius: 10px;
  margin: 0 10px;
  width: 100px; /* Adjust width as needed */
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s ease;
}

.step-number {
  font-size: 2em;
  color: #ff6f00;
  margin-bottom: 5px;
  font-weight: bold;
}

.step-title {
  font-size: 0.7em;
  color: #fff;
}

.crown {
  content: "";
  position: absolute;
  top: -35px;
  width: 50px;
  height: 30px;
  background-size: contain;
  background-repeat: no-repeat;
  left: calc(50% + 2px);
  transform: translateX(-50%);
  z-index: 10;
}

.crownSilver {
  content: "";
  position: absolute;
  top: -35px;
  width: 50px;
  height: 30px;
  background-size: contain;
  background-repeat: no-repeat;
  left: calc(75% + 2px);
  transform: translateX(-50%);
  z-index: 10;
}

/* Specific styles for each place */
.place-1 {
  order: 2;
  padding-top: 20px; /* Make the first place podium taller */
}

.place-2 {
  order: 1;
}

.place-3 {
  order: 3;
}
</style>
