import constants from '@/constants/static.json'

export default function Leaderboard() {
  const currentPerformanceTableColumnsEnglish = [
    {
      key: 'PLACE', label: 'PLACE', thStyle: { width: '10%' }, tdStyle: { width: '10%' },
    },
    {
      key: 'NAME', label: 'NAME', thStyle: { width: '40%' }, tdStyle: { width: '40%' },
    },
    {
      key: 'POINTS', label: 'POINTS FOR PERIOD', thStyle: { width: '20%' }, tdStyle: { width: '20%' },
    },
    {
      key: 'BADGE', label: '', thStyle: { width: '20%' }, tdStyle: { width: '20%' },
    },
    {
      key: 'GOALS', label: 'GOALS', thStyle: { width: '10%' }, tdStyle: { width: '10%' },
    },
  ]

  const currentPerformanceTableColumnsSwedish = [
    {
      key: 'PLACE', label: 'PLACERING', thStyle: { width: '10%' }, tdStyle: { width: '10%' },
    },
    {
      key: 'NAME', label: 'NAMN', thStyle: { width: '40%' }, tdStyle: { width: '40%' },
    },
    {
      key: 'POINTS', label: 'POÄNG DENNA PERIOD', thStyle: { width: '20%' }, tdStyle: { width: '20%' },
    },
    {
      key: 'BADGE', label: '', thStyle: { width: '20%' }, tdStyle: { width: '20%' },
    },
    {
      key: 'GOALS', label: 'MÅL', thStyle: { width: '10%' }, tdStyle: { width: '10%' },
    },
  ]

  // Table Handlers
  const overallPerformanceTableColumnsEnglish = [
    {
      key: 'PLACE', label: 'PLACE', thStyle: { width: '10%' }, tdStyle: { width: '10%' },
    },
    {
      key: 'NAME', label: 'NAME', thStyle: { width: '40%' }, tdStyle: { width: '40%' },
    },
    {
      key: 'POINTS', label: 'POINTS(XP)', thStyle: { width: '20%' }, tdStyle: { width: '20%' },
    },
    {
      key: 'BADGE', label: 'BADGE', thStyle: { width: '20%' }, tdStyle: { width: '20%' },
    },
    {
      key: 'GOALS', label: 'GOALS', thStyle: { width: '10%' }, tdStyle: { width: '10%' },
    },
  ]

  const overallPerformanceTableColumnsSwedish = [
    {
      key: 'PLACE', label: 'PLACERING', thStyle: { width: '10%' }, tdStyle: { width: '10%' },
    },
    {
      key: 'NAME', label: 'NAMN', thStyle: { width: '40%' }, tdStyle: { width: '40%' },
    },
    {
      key: 'POINTS', label: 'POÄNG(XP)', thStyle: { width: '20%' }, tdStyle: { width: '20%' },
    },
    {
      key: 'BADGE', label: 'MEDALJ', thStyle: { width: '20%' }, tdStyle: { width: '20%' },
    },
    {
      key: 'GOALS', label: 'MÅL', thStyle: { width: '10%' }, tdStyle: { width: '10%' },
    },
  ]

  const backgroundColor = color => `background-color:${color}`

  const sliceSortByValue = data => {
    const list = data.slice(0, constants.PAGES.LEADERBOARD_VIEW_PER_PAGE)
    list.sort((a, b) => b.points - a.points)
    // eslint-disable-next-line no-use-before-define
    return setPlace(list)
  }
  const setPlace = data => {
    const results = []
    data.forEach((item, index) => {
      // eslint-disable-next-line no-param-reassign
      item.place = index + 1
      results.push(item)
    })
    return results
  }

  return {
    currentPerformanceTableColumnsEnglish,
    overallPerformanceTableColumnsEnglish,
    currentPerformanceTableColumnsSwedish,
    overallPerformanceTableColumnsSwedish,
    sliceSortByValue,
    backgroundColor,
  }
}
